'use client'

import Section from '@lyra/core/components/Section'
import { SpinnerProps } from '@lyra/core/components/Section/SectionSpinner'
import React from 'react'
import { YStack } from 'tamagui'

type Props = SpinnerProps

export default function LoadingPageHelper({ ...spinnerProps }: Props) {
  return (
    <YStack flexGrow={1} flexShrink={1} alignItems="center" justifyContent="center" padding="$6">
      <Section noTopBorder>
        <Section.Spinner {...spinnerProps} />
      </Section>
    </YStack>
  )
}
