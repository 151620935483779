import {
  GetProps,
  Separator,
  styled,
  TamaguiComponent,
  withStaticProperties,
  YStack,
} from 'tamagui'

import SectionChart from './SectionChart'
import SectionGrid from './SectionGrid'
import SectionHeader from './SectionHeader'
import SectionPageHeader from './SectionPageHeader'
import SectionSpinner from './SectionSpinner'
import SectionSubheader from './SectionSubheader'
import SectionXStack from './SectionXStack'
import SectionYStack from './SectionYStack'

const Section = styled(YStack, {
  borderColor: '$hairline',
  paddingVertical: '$3',
  rowGap: '$3',
  backgroundColor: '$appBg',
  variants: {
    isLarge: {
      true: {
        $desktop: {
          // only add horizontal padding on desktop
          paddingHorizontal: '$1',
        },
        paddingVertical: '$6',
        rowGap: '$5',
      },
    },
    isLargeHorizontal: {
      true: {
        $desktop: {
          // only add horizontal padding on desktop
          paddingHorizontal: '$1',
        },
      },
    },
    noBottomPadding: {
      true: {
        paddingBottom: 0,
      },
    },
    isClickable: {
      true: {
        cursor: 'pointer',
        hoverStyle: { backgroundColor: '$hoverBg' },
        pressStyle: { backgroundColor: '$pressBg' },
      },
      false: {},
    },
    noTopBorder: {
      true: {
        borderTopWidth: 0,
      },
      false: {
        borderTopWidth: 1,
      },
    },
    showBottomBorder: {
      true: {
        borderBottomWidth: 1,
      },
      false: {
        borderBottomWidth: 0,
      },
    },
  },
  defaultVariants: {
    // TODO: @earthtojake replace noTopBorder with showTopBorder
    showBottomBorder: false,
    noTopBorder: false,
    isLarge: false,
    isClickable: false,
  },
})

const SectionSeparator = styled(Separator, {
  borderColor: '$hairline',
  variants: {
    vertical: {
      true: {
        marginVertical: 0,
      },
      false: {
        // marginVertical: '$1',
      },
    },
    noPadding: {
      true: {
        marginHorizontal: 0,
      },
      false: {
        marginHorizontal: '$3',
      },
    },
  },
  defaultVariants: {
    noPadding: false,
    vertical: false,
  },
})

const SectionTable = styled(SectionYStack, {
  paddingHorizontal: '$1',
})

type SectionLayoutOmitProps = 'gap' | 'rowGap' | 'space' | `padding${string}` | `margin${string}`

type SectionStackLayoutOmitProps =
  | 'gap'
  | 'rowGap'
  | 'space'
  | `padding${string}`
  | `margin${string}`

export type SectionProps = Omit<GetProps<typeof Section>, SectionLayoutOmitProps>
export type SectionStackProps = Omit<GetProps<typeof SectionXStack>, SectionStackLayoutOmitProps>
export type SectionChartProps = Omit<GetProps<typeof SectionChart>, SectionStackLayoutOmitProps>

export default withStaticProperties(Section as TamaguiComponent<SectionProps>, {
  XStack: SectionXStack as TamaguiComponent<
    Omit<GetProps<typeof SectionXStack>, SectionStackLayoutOmitProps>
  >,
  YStack: SectionYStack as TamaguiComponent<
    Omit<GetProps<typeof SectionYStack>, SectionStackLayoutOmitProps>
  >,
  Grid: SectionGrid as TamaguiComponent<
    Omit<GetProps<typeof SectionGrid>, SectionStackLayoutOmitProps>
  >,
  Separator: SectionSeparator,
  Header: SectionHeader as TamaguiComponent<
    Omit<GetProps<typeof SectionHeader>, SectionStackLayoutOmitProps>
  >,
  Subheader: SectionSubheader as TamaguiComponent<
    Omit<GetProps<typeof SectionSubheader>, SectionStackLayoutOmitProps>
  >,
  PageHeader: SectionPageHeader as TamaguiComponent<
    Omit<GetProps<typeof SectionPageHeader>, SectionStackLayoutOmitProps>
  >,
  Spinner: SectionSpinner as TamaguiComponent<
    Omit<GetProps<typeof SectionSpinner>, SectionStackLayoutOmitProps>
  >,
  Table: SectionTable as TamaguiComponent<
    Omit<GetProps<typeof SectionTable>, SectionStackLayoutOmitProps>
  >,
  Chart: SectionChart as TamaguiComponent<SectionChartProps>,
})
